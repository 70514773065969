import React, { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { useEsperanto } from "../context/EsperantoContext";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../i18n";
import { Link } from "react-router-dom";
import ResponsiveImage from "./Img/ResponsiveImage";
// import videoMov from '../images/video.mov';
// import videoWebm from '../images/video.webm';

const links = [
  "nav.menu1",
  "nav.menu2",
  "nav.menu3",
  "nav.menu4",
  "nav.menu5",
  // "nav.menu6",
];
const Navbar = () => {
  const { i18n, t } = useTranslation();
  const {
    dispatch,
    state: { menuToggle, isScrolling },
  } = useEsperanto();
  const videoSpeed = document.getElementById("menuVideo");
  const setPlaySpeed = () => {
    videoSpeed.playbackRate = 0.5;
  };
  useEffect(() => {
    // Retrieve the language from storage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // Set the language in browser storage when it changes
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };
  return (
    <NavbarDiv open={{ menuToggle, isScrolling }}>
      <ul>
        <div>
          <video
            id="menuVideo"
            autoPlay
            loop
            muted
            playsInline
            src="assets/videos/video.mp4"
          >
            <source src="assets/videos/video.mp4" type="video/mp4" />
            <source src="assets/videos/video.mov" type="video/mov" />
          </video>
          <ResponsiveImage src={"esperanto-slider-bg.jpg"} alt="navbar-bg" />
        </div>
        {links.map((el, ind) => (
          <li key={uuidv4()}>
            <a
              href={`/#${t(`section${ind + 1}`)}`}
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t(el)}
            </a>
          </li>
        ))}
        <div>
          <span>
            <ResponsiveImage
              src={"icons/divider-icon.svg"}
              alt="divider-icon"
            />
          </span>
        </div>
        <li
          onClick={() => {
            handleLanguageChange(i18n.language === "en" ? "fr" : "en");
            dispatch({ type: "menu-toggle", payload: false });
          }}
        >
          <Link>{i18n.language === "en" ? "fr" : "en"}</Link>
        </li>
      </ul>
      <StyledBurger
        aria-label="hamburger"
        open={{ menuToggle, isScrolling }}
        onClick={() => {
          menuToggle === true
            ? dispatch({ type: "menu-toggle", payload: false })
            : dispatch({ type: "menu-toggle", payload: true });
          setPlaySpeed();
        }}
      >
        {" "}
        <div />
        <div />
      </StyledBurger>
      <a href={`/#${t("section1")}`}>
        <ResponsiveImage src={"logo/esperanto_logo_white-registered.png"} alt="logo" />
      </a>
    </NavbarDiv>
  );
};
const slide = keyframes`
from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`;
const slideBack = keyframes`
from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;
const NavbarDiv = styled.div`
  position: fixed;
  height: 100px;
  width: 100vw;
  z-index: 9;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  /* & > nav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    flex: 80px 1fr;
    align-items: center;
    justify-content: space-between;
    p:nth-child(2) {
      margin-right: 80px;
      position: relative;
      flex-basis: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 2px solid var(--grey);
      font-size: 16px;
      span {
        width: 60px;
        border-bottom: 1px solid var(--grey);
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  } */
  ul {
    display: flex;
    gap: 30px;
    transition: all 0.4s;
    opacity: ${({ open }) => (open.menuToggle ? "1" : "0")};
    z-index: ${({ open }) => (open.menuToggle ? "10" : "-1")};
    pointer-events: ${({ open }) => !open.menuToggle && "none"};
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    animation: ${({ open }) =>
      open.menuToggle &&
      css`
        ${slide} 0.3s ease-in-out forwards
      `};
    animation: ${({ open }) =>
      !open.menuToggle &&
      css`
        ${slideBack} 0.3s ease-in-out backwards
      `};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--black);
    list-style: none;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > li {
      z-index: 100;
      a {
        display: block;
        margin: 5px 20px;
        font-size: 2.5rem;
        color: var(--white);
        text-decoration: none;
        transition: 0.4s scale;
        &:hover {
          color: var(--white);
          scale: 1.5;
          font-weight: bold;
        }
      }
    }
    & > div:nth-child(7) {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 380px;
      z-index: 100;
      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid var(--lightgrey);
        margin: auto;
      }
      &:before {
        margin-right: 20px;
      }
      &:after {
        margin-left: 20px;
      }
      & > span {
        & > img {
          height: 20px;
          width: auto;
        }
      }
    }
    & > li:last-child {
      text-transform: uppercase;
    }
    & > div:first-child {
      display: flex;
      align-items: center;
      height: 100vh;
      width: 100vw;
      position: absolute;
      z-index: 12;
      opacity: 0.4;
      left: 0;
      top: 0;
      display: none;
      & > video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        overflow: hidden;
      }
      & > img {
        display: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  & > a {
    & > img {
      object-fit: contain;
      height: auto;
      width: 100%;
      position: fixed;
      pointer-events: ${({ open }) => (!open.isScrolling ? "unset" : "none")};
      max-width: ${({ open }) => (!open.isScrolling ? "145px" : "350px")};
      max-height: ${({ open }) => (!open.isScrolling ? "75px" : "auto")};
      left: 50%;
      top: ${({ open }) => (!open.isScrolling ? "60px" : "50%")};
      transform: translate(-50%, -65%);
      transition: all 0.3s ease;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    height: 80px;
    & > ul {
      & > div:first-child {
        & > video {
          display: none;
        }
        & > img {
          display: block;
        }
      }
      & > div:nth-child(7) {
        & > span {
          & > img {
            object-fit: contain;
          }
        }
        & > div:nth-child(8) {
          padding: 0 20px;
        }
        & > li {
          & > a {
            font-size: 2em;
          }
        }
      }
      & > a {
        & > img {
          top: ${({ open }) => (!open.isScrolling ? "50px" : "43%")};
          max-width: ${({ open }) => (!open.isScrolling ? "70px" : "350px")};
        }
      }
    }
  }
`;
const StyledBurger = styled.button`
  left: 40px;
  top: 26px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${({ open }) =>
    !open.isScrolling ? "rgba(0,0,0,0.7)" : "transparent"};
  transition: 0.4s border;
  border: 2px solid var(--lightgreen);
  font-size: unset;
  border-radius: 50%;
  letter-spacing: unset;
  z-index: 11;
  cursor: pointer;
  padding: 0;
  padding-top: 5px;
  &:focus {
    outline: none;
  }
  /* &:hover {
    background: var(--white);
    transition: 0.4s all;
    & > div {
      background: var(--black);
    }
  } */
  div {
    transition: 0.4s all;
    width: 22px;
    height: 1px;
    background: var(--white);
    border-radius: 10px;
    position: relative;
    transform-origin: 4.5px;
    padding-bottom: 2px;
    :first-child {
      transform: ${({ open }) =>
        open.menuToggle ? "rotate(45deg)" : "rotate(0)"};
      margin-bottom: 7px;
      margin-left: ${({ open }) => (open.menuToggle ? "3px" : "0")};
    }
    :last-child {
      transform: ${({ open }) =>
        open.menuToggle ? "rotate(-45deg)" : "rotate(0)"};
      margin-bottom: 7px;
      margin-left: ${({ open }) => (open.menuToggle ? "3px" : "0")};
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    left: 20px;
    top: 20px;
    border: 1px solid var(--lightgreen);
  }
`;
// const Language = styled.div`
//   z-index: 100;
//   color: var(--white);
//   cursor: pointer;
//   position: fixed;
//   height: 40px;
//   width: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   bottom: 40px;
//   text-transform: uppercase;
//   /* border: 1px solid var(--white); */
//   & > div {
//     vertical-align: bottom;
//     font-size: 40px;
//     font-weight: 100;
//     font-family: "Champagn & Limousines";
//     /* margin: 8px; */
//   }
//   & > div::after {
//     content: "";
//     display: block;
//     width: 0;
//     height: 1px;
//     background: var(--lightgrey);
//     transition: width 0.3s ease-in;
//   }
//   & > div:hover::after {
//     width: 100%;
//   }
//   &:hover {
//     transition: ease-in-out 0.3s;
//     color: white;
//     font-size: 105%;
//   }
//   @media screen and (min-width: 992px) {
//     /* start of phone and medium tablet styles */
//     display: none;
//   }
// `;
export default Navbar;
